/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-shadow */
/* eslint-disable radix */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import api from "../../services/api";
import { Backr, Form } from "./styles";
import { signOut } from "../../store/modules/auth/actions";
import history from "../../services/history";
import UsageReport from "./components/UsageReport";
import LogsReport from "./components/LogsReport";

export default function CS() {
  const [clinics, setClinics] = useState([]);
  const [protocols, setProtocols] = useState([]);
  // const [logs, setLogs] = useState([]);
  const dispatch = useDispatch();
  const role = useSelector((state) => state.auth.role);

  const {
    register: registerProtocol,
    handleSubmit: handleSubmitProtocol,
    setValue,
  } = useForm();

  const onSubmitProtocol = (data) => {
    async function request() {
      const send = await api
        .post(`/copy_protocols`, data)
        .then((response) => {
          toast.success(response.data.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
      return send;
    }
    request();
  };

  // Pegar quais as clínicas temos cadastradas
  useEffect(() => {
    async function loadClinics() {
      const response = await api.get(`/clinicas`).catch((error) => {
        try {
          if (error.response.status === 401) {
            toast.error("Você precisa fazer login");
            dispatch(signOut());
            history.push("/cs");
          }
        } catch {
          toast.error("Você precisa fazer login");
          dispatch(signOut());
          history.push("/cs");
        }
      });
      setClinics(response.data);
    }
    loadClinics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Pegar os protocolos disponiveis
  useEffect(() => {
    async function loadProtocols() {
      const response = await api.get(`/protocols`);
      setProtocols(response.data);
    }
    loadProtocols();
  }, []);

  // Função para criar o select da clinica
  function selectClinic2(calledFunction) {
    return (
      <div>
        <input
          type="text"
          name="clinicId"
          label="clinicId"
          {...calledFunction("clinicId", { required: true })}
          list="clinicId"
          style={{ width: "100%", marginLeft: "5px" }}
        />
        <datalist id="clinicId">
          {clinics.map((clinicas) => (
            <option
              key={clinicas.id}
              id={clinicas.id}
              value={`${clinicas.id} - ${clinicas.clinica}`}
            />
          ))}
        </datalist>
      </div>
    );
  }

  function changeProtocol(e) {
    const selectedProtocol = protocols.filter(
      (x) => x.codigo === parseInt(e.target.value, 10)
    );
    setValue("selectedProtocol", selectedProtocol);
  }

  // Função para criar o select do protocolo
  function selectProtocol() {
    return (
      <select onChange={changeProtocol} style={{ marginRight: "5px" }}>
        <option defaultValue value="" disabled>
          Selecione um protocolo
        </option>
        {protocols.map((protocol) => (
          <option
            key={protocol.codigo}
            id={protocol.codigo}
            value={protocol.codigo}
          >
            {protocol.nome}
          </option>
        ))}
      </select>
    );
  }

  function insertProtocols(role) {
    if (role === "MASTER" || role === "CS") {
      return (
        <Backr>
          <h1>
            <FaArrowUp color="#333" />
            Adicionar protocolo
          </h1>
          <Form key={2} onSubmit={handleSubmitProtocol(onSubmitProtocol)}>
            {selectProtocol()}
            {selectClinic2(registerProtocol)}
            <Button type="submit">Adicionar protocolo</Button>
          </Form>
        </Backr>
      );
    }
    return <></>;
  }

  return (
    <>
      {/* Inserção de Protocolos */}
      {insertProtocols(role)}

      {/* Logs Administrativo */}
      {LogsReport(role)}

      {/* Gerar relatorio de uso */}
      {UsageReport(role)}
      <footer>
        <p>.</p>
      </footer>
    </>
  );
}
