import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";
import history from "../../../services/history";
import { signInSuccess, signFailure } from "./actions";
import api from "../../../services/api";

export function* signIn({ payload }) {
  try {
    // ta colocando tudo dentro de email no payload. precisa arrumar
    const { email, password } = payload.email;
    // const { email } = payload.email;
    // const { password } = payload.password;
    const response = yield call(api.post, "/login", { email, password });

    const { token } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token));
    const role = jwt_decode(token).systemRole;
    // eslint-disable-next-line no-unused-expressions
    role === "MASTER" || role === "CS"
      ? history.push("/cs")
      : history.push("/comercial");
    // toast.success("Login realizado com sucesso");
    // window.location.href = "/cs";
  } catch (err) {
    toast.error("Falha na autenticação, verifique seus dados");
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push("/");
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest("@auth/SIGN_IN_REQUEST", signIn),
  takeLatest("@auth/SIGN_OUT", signOut),
]);
