import styled from "styled-components";

export const Backr = styled.div`
  max-width: 700px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  padding: 30px;
  margin: 50px auto;

  h1 {
    font-size: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  td {
    border: solid;
    border-width: 0.5px 0;
    padding: 10px 0;
  }

  td:first-child {
    width: 40%;
  }

  td:first-child + td {
    width: 30%;
  }

  td:first-child + td + td {
    width: 20%;
  }

  td:first-child + td + td + td {
    width: 10%;
    Col {
      padding: 5px;
    }
  }

  Col {
    padding: 5px;
  }
`;
