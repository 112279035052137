/* eslint-disable no-plusplus */
export function cleanNumber(inputText) {
  const cleanedNumber = inputText.replace(/[^0-9]/g, "");
  return cleanedNumber;
}

export function convertToLowerCase(str) {
  str.toLowerCase();
  const arr = str.split(" ");
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
}

export function mudaroCEP(valor) {
  valor.replace(/\D/g, "");
  valor.replace(/^(\d{5})(\d)/g, "$1-$2");
  return valor; // Insere o(s) valor(es) no campo
}
