/* eslint-disable import/order */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { convertToLowerCase, cleanNumber, mudaroCEP } from "./auxFunctions";
import api from "../../services/api";
import axios from "axios";
import { useSelector } from "react-redux";

export default function NewContract() {
  const userRole = useSelector((state) => state.auth.role);
  const { register, getValues, setValue } = useForm();
  const [buttonStatus, setButtonStatus] = useState([]);
  const [buttonMessage, setButtonMessage] = useState([]);

  const [masterCreateBaseButton, setMasterCreateBaseButton] = useState([]);

  const [prices, setPrices] = useState([]);
  useEffect(() => {
    async function loadRoles() {
      const response = await api.get(`/prices`);
      setPrices(response.data);
      setButtonStatus(false);
      setButtonMessage("Solicitar proposta");
      setMasterCreateBaseButton("Criar base sem contrato");
    }
    loadRoles();
  }, []);

  function updateValues(id, value) {
    document.getElementById(id).value = id;
    setValue(id, value);
  }

  const mascaraCNPJ = () => {
    let cnpj = document.getElementById("cnpj").value;
    if (cnpj) {
      cnpj = cnpj.replace(/\D/g, "");
      cnpj = cnpj.replace(/^(\d{2})(\d)/g, "$1.$2");
      cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2");
      cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2");
      cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
      updateValues("cnpj", cnpj); // Insere o(s) valor(es) no campo
    }
  };

  const updatePrice = () => {
    const amount = document.getElementById("creditsAmount").value;
    const priceElement = document.getElementById("totalPrice");
    const priceUserElement = document.getElementById("pricePerUser");
    if (document.getElementById("PlanType").value === "Profissional") {
      // eslint-disable-next-line no-restricted-syntax
      for (const priceCategory of prices.Profissional) {
        if (priceCategory.max >= amount) {
          const promoCodeHTML = document.getElementById("promoCode");
          if (!(priceCategory.min === 250)) {
            const html = `<p> Faltam apenas ${
              priceCategory.max - (amount - 1)
            } pacientes para a próxima faixa de preço <p>`;
            promoCodeHTML.innerHTML = html;
            promoCodeHTML.style.visibility = "visible";
          } else {
            promoCodeHTML.style.visibility = "hidden";
          }
          const totalPrice = parseFloat(amount * priceCategory.price_per_credit)
            .toFixed(2)
            .replace(".", ",");
          priceElement.innerHTML = `R$ ${totalPrice}`;
          priceUserElement.innerHTML = `R$ ${parseFloat(
            priceCategory.price_per_credit
          )
            .toFixed(2)
            .replace(".", ",")}`;
          break;
        }
      }
    } else {
      const totalPrice = parseFloat(amount * prices.Basico[0].price_per_credit)
        .toFixed(2)
        .replace(".", ",");
      priceElement.innerHTML = `R$ ${totalPrice}`;
      priceUserElement.innerHTML = `R$ ${parseFloat(
        prices.Basico[0].price_per_credit
      )
        .toFixed(2)
        .replace(".", ",")}`;
    }
  };

  const searchCnpjData = () => {
    mascaraCNPJ();
    let cnpj = document.getElementById("cnpj").value;
    if (cnpj !== "") {
      if (cnpj.length >= 18) {
        cnpj = cleanNumber(cnpj);
        axios
          .get(`https://api-publica.speedio.com.br/buscarcnpj?cnpj=${cnpj}`)
          .then((data) => {
            data = data.data;
            if (data.error) {
              toast.error(data.error);
            } else {
              updateValues(
                "clinicName",
                convertToLowerCase(data["RAZAO SOCIAL"])
              );
              updateValues(
                "street",
                `${convertToLowerCase(
                  data["TIPO LOGRADOURO"]
                )} ${convertToLowerCase(data.LOGRADOURO)}`
              );
              updateValues("city", convertToLowerCase(data.MUNICIPIO));
              updateValues("cep", mudaroCEP(data.CEP));
              updateValues("uf", data.UF);
              updateValues("neighborhood", convertToLowerCase(data.BAIRRO));
            }
          });
      }
    }
  };

  const searchCEP = () => {
    let cep = document.getElementById("cep").value;
    if (cep !== "") {
      cep = cleanNumber(cep);
      axios.get(`https://viacep.com.br/ws/${cep}/json`).then((data) => {
        data = data.data;
        if (data.error) {
          toast.error(data.error);
        }
        updateValues("street", data.logradouro);
        updateValues("city", data.localidade);
        updateValues("uf", data.uf);
        updateValues("neighborhood", data.bairro);
      });
    }
  };

  const [roles, setRoles] = useState([]);
  useEffect(() => {
    async function loadRoles() {
      const response = await api.get(`/roles`);
      setRoles(response.data);
    }
    loadRoles();
  }, []);

  const mascaraTelefone = () => {
    let valor = document.getElementById("phoneNumber").value;
    valor = valor.replace(/\D/g, "");
    valor = valor.replace(/^(\d{2})(\d)/g, "($1) $2");
    valor = valor.replace(/(\d)(\d{4})$/, "$1-$2");
    updateValues("phoneNumber", valor); // Insere o(s) valor(es) no campo
  };

  const mascaraCEP = () => {
    let valor = document.getElementById("cep").value;
    if (valor) {
      valor = valor.replace(/\D/g, "");
      valor = valor.replace(/^(\d{5})(\d)/g, "$1-$2");
      updateValues("cep", valor); // Insere o(s) valor(es) no campo
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (isNaN(getValues().role_code)) toast.error("selecione o cargo!");
    else {
      setButtonStatus(true);
      setButtonMessage("Solicitando");
      try {
        api
          .post(`/request_commercial_offer`, getValues())
          .then((data) => {
            toast.success(data.data.message);
            setButtonStatus(false);
            setButtonMessage("Solicitar orçamento");
          })
          .catch((response) => {
            console.log("ERRO");
            toast.error(
              response.response
                ? response.response.data.message
                : "Erro inesperado! Consulte os logs"
            );
            setButtonStatus(false);
            setButtonMessage("Solicitar orçamento");
          });
      } catch {
        toast.error(
          "Ocorreu um erro ao processar seu pedido! Solicite ajuda do time de desenvolvimento."
        );
        setButtonStatus(false);
        setButtonMessage("Solicitar orçamento");
      }
    }
  };

  const handleSubmitMasterForm = (e) => {
    e.preventDefault();
    if (isNaN(getValues().role_code)) toast.error("selecione o cargo!");
    else {
      setButtonStatus(true);
      setButtonMessage("Solicitando");
      try {
        api
          .post(`/create_base`, getValues())
          .then((data) => {
            toast.success(data.data.message);
            setButtonStatus(false);
            setButtonMessage("Solicitar orçamento");
          })
          .catch((response) => {
            console.log("ERRO");
            toast.error(
              response.response
                ? response.response.data.message
                : "Erro inesperado! Consulte os logs"
            );
            setButtonStatus(false);
            setButtonMessage("Solicitar orçamento");
          });
      } catch {
        toast.error(
          "Ocorreu um erro ao processar seu pedido! Solicite ajuda do time de desenvolvimento."
        );
        setButtonStatus(false);
        setButtonMessage("Solicitar orçamento");
      }
    }
  };

  return (
    <>
      <Container>
        <Row className="min-vh-100 d-flex flex-column justify-content-center">
          <Col sm={9} md={7} lg={6} className="mx-auto">
            <div className="card card-signin my-4">
              <div className="card-body">
                <Form className="form-request-commercial-offer">
                  {/* Company Area */}
                  <h3>Informações da Empresa</h3>
                  <Form.Group
                    className="mb-3 input-area"
                    onBlur={searchCnpjData}
                  >
                    <Form.Label htmlFor="cnpj">CNPJ:</Form.Label>
                    <Form.Control
                      type="text"
                      id="cnpj"
                      name="cnpj"
                      minLength="18"
                      maxLength="18"
                      onKeyPress={mascaraCNPJ}
                      {...register("cnpj", { required: true })}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 input-area">
                    <Form.Label htmlFor="clinicName">
                      Nome da empresa
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="clinicName"
                      name="clinicName"
                      required
                      {...register("clinicName", { required: true })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 input-area" onBlur={searchCEP}>
                    <Form.Label htmlFor="cep">CEP</Form.Label>
                    <Form.Control
                      type="text"
                      id="cep"
                      name="cep"
                      maxlength="9"
                      required
                      onKeyPress={mascaraCEP}
                      {...register("cep", { required: true })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 input-area">
                    <Form.Label htmlFor="neighborhood">Bairro</Form.Label>
                    <Form.Control
                      type="text"
                      id="neighborhood"
                      name="neighborhood"
                      required
                      {...register("neighborhood", { required: true })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 input-area">
                    <Form.Label htmlFor="street">Rua</Form.Label>
                    <Form.Control
                      type="text"
                      id="street"
                      name="street"
                      required
                      {...register("street", { required: true })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 input-area">
                    <Form.Label htmlFor="addressComplement">
                      Complemento
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="addressComplement"
                      name="addressComplement"
                      {...register("addressComplement", { required: false })}
                    />
                  </Form.Group>
                  <Row>
                    <Form.Group className="mb-3 col-6 input-area">
                      <Form.Label htmlFor="city">Cidade</Form.Label>
                      <Form.Control
                        type="text"
                        id="city"
                        name="city"
                        required
                        {...register("city", { required: true })}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 col-6 input-area">
                      <Form.Label htmlFor="uf">Estado</Form.Label>
                      <Form.Control
                        as="select"
                        id="uf"
                        name="uf"
                        required
                        {...register("uf", { required: true })}
                      >
                        <option selected disabled value="">
                          UF
                        </option>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                      </Form.Control>
                    </Form.Group>
                  </Row>

                  {/* Contract Area */}
                  <hr />
                  <h3>Pacientes na plataforma</h3>
                  <Form.Group
                    className="mb-3 input-area"
                    onChange={updatePrice}
                  >
                    <Form.Label htmlFor="PlanType">Plano</Form.Label>
                    <Form.Control
                      as="select"
                      id="PlanType"
                      name="PlanType"
                      required
                      {...register("PlanType", { required: true })}
                    >
                      <option defaultValue selected value="Profissional">
                        Profissional
                      </option>
                      <option value="Basico">Básico</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group
                    className="mb-3 input-area"
                    onChange={updatePrice}
                  >
                    <Form.Label htmlFor="creditsAmount">
                      Quantidade de pacientes
                    </Form.Label>
                    <Form.Control
                      type="number"
                      id="creditsAmount"
                      name="creditsAmount"
                      min={5}
                      required
                      {...register("creditsAmount", { required: true })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <Form.Label htmlFor="creditsAmount">
                          Valor ativação
                        </Form.Label>
                        <Form.Control
                          type="number"
                          id="activation_fee"
                          name="activation_fee"
                          defaultValue={3000.0}
                          required
                          {...register("activation_fee", { required: true })}
                        />
                      </Col>
                      <Col style={{ padding: 0 }}>
                        <Row>
                          <Col align="right">
                            <span>Base teste</span>
                          </Col>
                          <Col align="left">
                            <Form.Check
                              type="checkbox"
                              id="isTest"
                              name="isTest"
                              {...register("isTest")}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                  <div id="promoCode">
                    <span style={{ visibility: "hidden" }}>
                      Faltam apenas 000 pacientes para a próxima faixa de preço
                    </span>
                  </div>
                  <div className="row align-middle">
                    <div className="col-6">
                      <span>Preço por mês:</span>
                      <h5 id="totalPrice">.</h5>
                    </div>
                    <div className="col-6">
                      <span>Preço por usuário/mês:</span>
                      <h5 id="pricePerUser">.</h5>
                    </div>
                  </div>

                  {/* Personal Area */}
                  <hr />
                  <h3>Responsável pelo projeto</h3>
                  <Form.Group className="mb-3 input-area">
                    <Form.Label htmlFor="name">Nome do responsável</Form.Label>
                    <Form.Control
                      type="text"
                      id="name"
                      name="name"
                      min={5}
                      required
                      {...register("name", { required: true })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 input-area">
                    <Form.Label htmlFor="email">E-mail</Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      name="email"
                      required
                      {...register("email", { required: true })}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 input-area"
                    onBlur={mascaraTelefone}
                  >
                    <Form.Label htmlFor="phoneNumber">Telefone</Form.Label>
                    <Form.Control
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      required
                      maxLength={15}
                      minLength={15}
                      onKeyPress={mascaraTelefone}
                      {...register("phoneNumber", { required: true })}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 input-area">
                    <Form.Label>Cargo</Form.Label>
                    <Form.Control
                      as="select"
                      id="role_code"
                      name="role_code"
                      list="role_code"
                      required
                      {...register("role_code", { required: true })}
                    >
                      <option selected disabled>
                        Cargo
                      </option>
                      {roles.map((role) => (
                        <option
                          id={role.descricao}
                          key={role.codigo}
                          value={role.codigo}
                        >
                          {role.descricao}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Button
                        onClick={handleSubmitForm}
                        className="btn"
                        disabled={buttonStatus}
                      >
                        {buttonMessage}
                      </Button>
                    </Col>
                    {(userRole === "MASTER" || userRole === "SALES") && (
                      <Col className="d-flex justify-content-center">
                        <Button
                          variant="outline-primary"
                          onClick={handleSubmitMasterForm}
                          disabled={buttonStatus}
                        >
                          {masterCreateBaseButton}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <footer>
        <p>.</p>
      </footer>
    </>
  );
}
