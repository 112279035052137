/* eslint-disable no-alert */
/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Row,
  Container,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import { FaSignInAlt } from "react-icons/fa";
import { Backr, ButtonDiv } from "./styles";
import { signInRequest } from "../../store/modules/auth/actions";

export default function Login() {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const onSubmit = ({ email, password }) => {
    dispatch(signInRequest({ email, password }));
  };

  return (
    <>
      <Backr>
        <Container>
          <br />
          <Row>
            <h1>Entrar</h1>
          </Row>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup controlId="formGroup">
              <FormLabel>Email:</FormLabel>
              <FormControl
                type="email"
                required="true"
                {...register("email", { required: true })}
              />
            </FormGroup>
            <br />
            <FormGroup controlId="formGroup">
              <FormLabel>Senha:</FormLabel>
              <FormControl
                type="password"
                required="true"
                {...register("password", { required: true })}
              />
            </FormGroup>
            <br />
            <ButtonDiv>
              <Button type="submit">
                {" "}
                <FaSignInAlt />
                {loading ? " Carregando..." : " Login"}
              </Button>
            </ButtonDiv>
          </Form>
        </Container>
      </Backr>
    </>
  );
}
