/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Button, FormControl, Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Backr } from "./styles";
import api from "../../../../services/api";
import history from "../../../../services/history";

function baseStatus() {
  const [allClinics, setallClinics] = useState([]);
  const { setValue, getValues } = useForm();
  const dispatch = useDispatch();

  // Pegar quais as clínicas temos cadastradas
  useEffect(() => {
    async function loadallClinics() {
      const response = await api.get(`/clinicas_all`).catch((error) => {
        try {
          if (error.response.status === 401) {
            toast.error("Você precisa fazer login");
            dispatch(signOut());
            history.push("/cs");
          }
        } catch {
          toast.error("Você precisa fazer login");
          dispatch(signOut());
          history.push("/cs");
        }
      });
      setallClinics(response.data);
    }
    loadallClinics();
  }, []);

  const showUpdateallClinicstatus = (e) => {
    let { value } = e.target;
    value = value.split(" - ")[0];
    if (value && !isNaN(value)) {
      setValue("tenant_id", value);
      api
        .get(`/base_status?tenant=${value}`)
        .catch(() => {
          toast.error("Erro ao carregar estado dessa clinica!");
        })
        .then((response) => {
          const responseStatus = response.data.status;
          setValue("status", response.data.status);
          const element = document.getElementById("tenantStatus");
          // eslint-disable-next-line no-unused-expressions
          responseStatus
            ? (element.options[1].selected = true)
            : (element.options[2].selected = true);
        });
    }
  };

  const changeStatus = (e) => {
    const { value } = e.target;
    setValue("status", value === "true");
  };

  const postChangeStatus = (e) => {
    e.preventDefault();
    api
      .post(`/change_base_status`, getValues())
      .then(() => {
        toast.success(`Status da empresa alterado para ${getValues().status}`);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  // Função para criar o select da clinica
  function selectClinic() {
    return (
      <div>
        <FormControl
          type="text"
          name="clinicId"
          label="clinicId"
          list="clinicId"
          style={{ width: "100%", marginLeft: "5px" }}
          onChange={showUpdateallClinicstatus}
          required
        />
        <datalist id="clinicId">
          {allClinics.map((clinicas) => (
            <option
              key={clinicas.id}
              id={clinicas.id}
              value={`${clinicas.id} - ${clinicas.clinica}`}
            />
          ))}
        </datalist>
      </div>
    );
  }

  return (
    <Form key={2} onSubmit={postChangeStatus}>
      <Row className="mb-1">
        <Col>{selectClinic()}</Col>
        <Col xs={3}>
          <Form.Control
            id="tenantStatus"
            as="select"
            onChange={changeStatus}
            required
          >
            <option defaultValue value="" disabled>
              Status
            </option>
            <option key="True" id="True" value="true">
              Ativada
            </option>
            <option key="False" id="False" value="false">
              Desativada
            </option>
          </Form.Control>
        </Col>
      </Row>
      <Button type="submit">Alterar status</Button>
    </Form>
  );
}

export default function ChangeTenantBaseStatus(role) {
  if (role === "MASTER") {
    return (
      <Backr>
        <h1>Mudar status da base</h1>
        {baseStatus()}
      </Backr>
    );
  }
  return <></>;
}
