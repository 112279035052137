import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Container } from "./styles";
import logo from "../../assets/logo.png";
import { signOut } from "../../store/modules/auth/actions";
import history from "../../services/history";

export default function Header() {
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(signOut());
    history.push("/");
  };

  const role = useSelector((state) => state.auth.role);

  const logoutButton = () => {
    if (role) {
      return (
        <Button type="button" onClick={handleSignOut}>
          Sair
        </Button>
      );
    }
    return <></>;
  };

  const navigationButtons = () => {
    if (!role) return <></>;
    if (role === "MASTER") {
      return (
        <>
          <Link to="/cs">
            <Button variant="outline-info" style={{ marginRight: "10px" }}>
              CS
            </Button>
          </Link>
          <Link to="/comercial">
            <Button variant="outline-info" style={{ marginRight: "10px" }}>
              Comercial
            </Button>
          </Link>
          <Link to="/novocontrato">
            <Button variant="outline-info" style={{ marginRight: "10px" }}>
              Novo Contrato
            </Button>
          </Link>
          <Link to="/master">
            <Button variant="outline-info" style={{ marginRight: "10px" }}>
              Master
            </Button>
          </Link>
        </>
      );
    }

    if (role === "CS" || role === "SALES") {
      return (
        <>
          <Link to="/novocontrato">
            <Button variant="outline-info" style={{ marginRight: "10px" }}>
              Novo Contrato
            </Button>
          </Link>
        </>
      );
    }
    return <></>;
  };

  const logoButton = () => {
    if (role === "SALES") {
      return (
        <>
          <Link to="/comercial">
            <img src={logo} alt="logo" width="250" />
          </Link>
        </>
      );
    }
    return (
      <>
        <Link to="/">
          <img src={logo} alt="logo" width="250" />
        </Link>
      </>
    );
  };

  return (
    <>
      <Container>
        {logoButton()}
        <Container>
          {navigationButtons()}
          {logoutButton()}
        </Container>
      </Container>
    </>
  );
}
