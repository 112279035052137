import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  justify-content: space-around;
  background-color: white;
  align-items: center;

  Button {
    height: 60%;
  }
`;
