import { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

export default createGlobalStyle`
    @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    html, body, #root{
        height: 100%;
    }

    -ms-hyphenate-limit-chars, border-style, #root{
        min-height: 100%auto;
    }

    a{
        text-decoration: none;
    }
    ul{
        list-style:none;
    }
    body {
        background: #02A3B7 !important;
        -webkit-font-smoothing: antialiased !important;
        font-family: "Roboto", sans-serif;
    }

    border-style, :-ms-input-placeholder, button {
        color: #222;
        font-size: 14px;
    }

    body, input, button{
        font: 14px Roboto, sans-serif;
        color: #333;
    }

    button {
        cursor: pointer;
    }

    h1{
        color: #333;
    }
`;
