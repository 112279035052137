import styled from "styled-components";

export const Backr = styled.div`
  max-width: 400px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  padding: 30px;
  margin: 50px auto;

  h1 {
    font-size: 34px;
    display: flex;
    justify-content: center;
  }
  button {
    align-items: center;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
`;
