import styled from "styled-components";

export const Backr = styled.div`
  max-width: 700px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  padding: 30px;
  margin: 50px auto;

  h1 {
    font-size: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
`;

export const TableCell = styled.div`
  background-color: #fefefe;
  span {
    margin-bottom: 20px;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  td {
    border: solid;
    border-width: 0.5px 0;
    padding: 10px 0;
  }

  td:first-child {
    width: 40%;
  }

  td:first-child + td {
    width: 30%;
  }

  td:first-child + td + td {
    width: 20%;
  }

  td:first-child + td + td + td {
    width: 10%;
    Col {
      padding: 5px;
    }
  }

  Col {
    padding: 5px;
  }
`;

// export const Table = styled.table`
//   border-collapse: collapse;
//   width: 100%;

//   td {
//     border: solid;
//     border-width: 0.5px 0;
//     padding: 10px 0;
//   }

//   td:first-child {
//     width: 40%;
//   }

//   td:first-child + td {
//     width: 30%;
//   }

//   td:first-child + td + td {
//     width: 20%;
//   }

//   td:first-child + td + td + td {
//     width: 10%;
//   }
// `;

export const Form = styled.form`
  margin-top: 30px;
  display: flex;
  flex-direction: row;

  input {
    width: 20%;
    border: 1px solid #eee;
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 16px;
    margin: 0 10px 0 10px;
  }

  select {
    width: 50%;
  }

  button {
    width: 30%;
    background: #fde480;
    font-size: 14px;
    font-weight: bold;
    padding: 0 15px;
    margin-left: 10px;
    color: #927503;
    border: 0px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 2%;
    }
  }
`;

export const SubmitButton = styled.button``;
