import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
/* <link rel="preconnect" href="https://fonts.gstatic.com/%22%3E" />
<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap" rel="stylesheet" /> */

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
