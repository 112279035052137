/* eslint-disable react/react-in-jsx-scope */
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Row, Col, Button, FormControl, Form } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { Backr } from "./styles";
import api from "../../../../services/api";

export default function CreateUser(role) {
  const {
    register: registerNewUser,
    handleSubmit: handleSubmitNewUser,
    setValue: setValuesNewUser,
  } = useForm();

  const changeRole = (e) => {
    setValuesNewUser("system_role", e.target.value);
  };

  const onSubmitNewUser = (data) => {
    async function request() {
      const send = await api
        .post(`/createuser`, data)
        .then((response) => {
          toast.success(
            `Usuario ${response.data.email} criado com cargo ${response.data.systemRole}`
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
      return send;
    }
    request();
  };

  if (role === "MASTER") {
    return (
      <Backr>
        <h1>Criar usuario Administrativo</h1>
        <Form onSubmit={handleSubmitNewUser(onSubmitNewUser)}>
          <Row>
            <Col cs={9}>
              <FormControl
                required
                id="email"
                type="input"
                {...registerNewUser("email", { required: true })}
              />
            </Col>
            <Col cs={3}>
              <Form.Control as="select" onChange={changeRole} required>
                <option defaultValue value="" disabled>
                  Função
                </option>
                <option key="MASTER" id="MASTER" value="MASTER">
                  Master
                </option>
                <option key="CS" id="CS" value="CS">
                  CS
                </option>
                <option key="SALES" id="SALES" value="SALES">
                  Comercial
                </option>
              </Form.Control>
            </Col>
          </Row>
          <Row className="mt-2 mb-2">
            <Col>
              <FormControl
                required
                id="password"
                type="password"
                {...registerNewUser("password", { required: true })}
              />
            </Col>
          </Row>
          <Col>
            <Button type="submit">
              <FaCheck /> Cadastrar
            </Button>
          </Col>
        </Form>
      </Backr>
    );
  }
  return <> </>;
}
