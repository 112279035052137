import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./components/Header";
import Routes from "./routes";
import GlobalStyle from "./styles/global";
import "bootstrap/dist/css/bootstrap.min.css";
import history from "./services/history";
import "./config/ReactotronConfig";
import { store, persistor } from "./store";

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <Router history={history}>
              <Header />
              <Routes />
              <GlobalStyle />
              <ToastContainer autoClose={3000} />
            </Router>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
