import React from "react";

import { Switch } from "react-router-dom";
import Route from "./Route";

import SignIn from "../pages/SignIn";
import CS from "../pages/CS";
import Comercial from "../pages/Comercial";
import NewContract from "../pages/NewContract";
import Master from "../pages/Master";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/cs" component={CS} isPrivate />
      <Route path="/comercial" component={Comercial} isPrivate />
      <Route path="/novocontrato" component={NewContract} isPrivate />
      <Route path="/master" component={Master} isPrivate />
      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}
