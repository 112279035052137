/* eslint-disable no-constant-condition */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Row, Col, Form, FormControl, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Backr } from "../CreateAdminUser/styles";
import api from "../../../../services/api";

/* eslint-disable react/react-in-jsx-scope */
export default function FinancialModule(role) {
  const { setValue, getValues } = useForm();

  const [clinicsFinancialModule, setClinicsFinancialModule] = useState([]);

  useEffect(() => {
    async function loadStatus() {
      const response = await api.get("/financialmodule").catch((error) => {
        toast.error(error);
      });
      setClinicsFinancialModule(response.data);
    }
    loadStatus();
  });

  // const handleClinicChange = (event) => {
  //   const selectedValue = event.target.value;
  //   const clinic = clinics.find(
  //     (clinic) =>
  //       `${clinic.empresa_codigo} - ${clinic.empresa_nome}` === selectedValue
  //   );
  //   setSelectedClinic(clinic);
  // };

  const changeStatus = (e) => {
    const { value } = e.target;
    setValue("financialModuleStatus", value === "true");
  };

  const showFinancelModuleStatus = (e) => {
    let { value } = e.target;
    value = parseInt(value.split(" - ")[0], 10);
    if (value && !Number.isNaN(value)) {
      const element = document.getElementById("financialModuleStatus");
      const clinic = clinicsFinancialModule.find(
        (currentClinic) => currentClinic.empresa_codigo === value
      );
      const currentStatus = clinic ? clinic.modulo_financeiro : null;
      setValue("companyId", value);
      setValue("financialModuleStatus", currentStatus);
      if (currentStatus !== null) {
        element.options[currentStatus ? 1 : 2].selected = true;
      }
    }
  };

  const postChangeFinancialModule = (e) => {
    e.preventDefault();
    const obj = getValues();
    if (
      Object.prototype.hasOwnProperty.call(obj, "companyId") &&
      Object.prototype.hasOwnProperty.call(obj, "financialModuleStatus")
    ) {
      api.post("/financialmodule", obj).then((response) => {
        toast.success(
          `Status do modulo financeiro para empresa alterado para ${response.data.modulo_financeiro ? "ativado" : "desativado"}`
        );
      });
    } else {
      toast.error(
        "Selecione a empresa e o estado do modulo financeiro para fazer uma alteração"
      );
    }
  };

  if (role === "MASTER") {
    return (
      <Backr>
        <h1>Módulo Financeiro</h1>
        <Form onSubmit={postChangeFinancialModule}>
          <Row className="mb-1">
            <Col>
              <FormControl
                type="text"
                name="clinicId"
                label="clinicId"
                list="clinicId"
                style={{ width: "100%", marginLeft: "5px" }}
                onChange={showFinancelModuleStatus}
              />
              <datalist id="clinicId">
                {clinicsFinancialModule.map((clinic) => (
                  <option
                    key={clinic.empresa_codigo}
                    id={clinic.empresa_codigo}
                    value={`${clinic.empresa_codigo} - ${clinic.empresa_nome}`}
                  >
                    {clinic.empresa_codigo} - {clinic.empresa_nome}
                  </option>
                ))}
              </datalist>
            </Col>

            <Col xs={3}>
              <Form.Control
                id="financialModuleStatus"
                as="select"
                onChange={changeStatus}
                required
              >
                <option defaultValue value="" disabled>
                  Status
                </option>
                <option key="True" id="True" value="true">
                  Ativado
                </option>
                <option key="False" id="False" value="false">
                  Desativado
                </option>
              </Form.Control>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button type="submit">Alterar Permissão Modulo Financeiro</Button>
            </Col>
          </Row>
        </Form>
      </Backr>
    );
  }
  return <> </>;
}
