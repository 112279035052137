/* eslint-disable react/react-in-jsx-scope */
import { useSelector } from "react-redux";
import LogsReport from "./components/LogsReport";
import BaseCreationToken from "./components/BaseCreationToken";
import CreateUser from "./components/CreateAdminUser";
import ChangeUserPassword from "./components/UpdatePassword";
import FinancialModule from "./components/FinancialModule";

export default function Master() {
  const role = useSelector((state) => state.auth.role);
  return (
    <>
      {/* Alterar a permissão do modulo financeiro */}
      {FinancialModule(role)}

      {/*  Liberar base */}
      {BaseCreationToken(role)}

      {/* Criar usuario ADM */}
      {CreateUser(role)}

      {/* Atualizar senha usuario ADM */}
      {ChangeUserPassword(role)}

      {/* Logs Administrativo */}
      {LogsReport(role)}
      <footer>
        <p>.</p>
      </footer>
    </>
  );
}
